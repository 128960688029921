.auth-client-page {
  background-color: #2280c1;
}

.patient-view .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  /* position: absolute; */
  /* background: #000000; */
  /* pointer-events: none; */
  height: 4px;
  background-color: #00538f;
}

.patient-view .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 16px;
  color: #00538f;
}

.patient-view .ant-skeleton-content .ant-skeleton-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.a2i-app .container {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 576px) {
  .a2i-app .container {
    max-width: none;
  }
}

@media (min-width: 1200px) {
  .a2i-app .container {
    max-width: 1250px;
  }
}

.a2i-app h3 {
  color: #00538f;
  font-size: 24px;
}

.a2i-app h1 {
  color: #17406c;
  font-size: 34px;
}
.a2i-app h2 {
  color: #17406c;
  font-size: 16px;
}

.a2i-app .ant-table-thead > tr > th {
  background-color: #f1f3f8;
}

.a2i-app .menu-button {
  background-color: #f1f3f8;
  border: none;
  font-weight: 600;
  border-radius: 200px;
}

.ant-dropdown-menu-vertical .ant-dropdown-menu-item {
  margin: 4px 0;
  padding: 0 16px;
  line-height: 40px;
}

.ant-dropdown-menu-vertical .ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 8px;
}

.ant-menu-dropdown {
  min-width: 250px;
}

.ant-menu-item-group {
  height: unset !important;
}

.ant-menu-item-group:hover {
  background: unset !important;
}

.ant-menu-item-option:active {
  background: #bac9cf;
  border-radius: 5px;
}

.menu-item {
  margin: 6px 0;
}

.ant-menu-dropdown .ant-menu-item:hover {
  background-color: #f1f3f8;
}

.ant-menu-item .ant-menu-item-text {
  font-size: 14px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
}

.menu-item .ant-menu-item-icon {
  padding: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #f1f3f8;
  color: #016ab6;
  border-radius: 10px;
  margin-right: 15px;
  width: 35px;
  vertical-align: middle;
}

.ant-select {
  border-radius: 10px;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
  display: block;
}

.ant-dropdown-menu-title-content a:hover {
  text-decoration: none;
}

.a2i-app a.learn-card {
  color: #001855;
}

.a2i-app .ant-btn-primary:not([disabled]):not(.ant-btn-background-ghost) {
  background-color: #00538f;
}

.a2i-app .ant-btn-primary:not(.ant-btn-background-ghost) {
  border: none;
}

.a2i-app .powered-by-canimmunize {
  max-width: 200px;
  filter: grayscale(100%);
  opacity: 0.8;
  transition: 0.5s;
}

.a2i-app .powered-by-canimmunize:hover {
  max-width: 200px;
  filter: none;
  opacity: 1;
}

.auth-code-container {
  padding: 16px;
  display: flex;
  justify-content: center;
}

.mobile-auth-code-container {
  padding: 4px;
  justify-content: center;
}

.auth-code-input {
  width: 2.7ch;
  padding: 8px;
  border-radius: 8px;
  font-size: 40px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid white;
  text-transform: uppercase;
  background-color: #f1f3f8;
}

.mobile-auth-code-input {
  width: 2.5ch;
  padding: 4px;
  border-radius: 8px;
  font-size: 30px;
  text-align: center;
  margin-right: 4px;
  border: 0.5px solid white;
  text-transform: uppercase;
  background-color: #f1f3f8;
}
/* the input type is number for the sake of phone keyboards: this removes the
arrows on desktop https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
.auth-code-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.auth-code-input input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 550px) {
  .auth-code-input {
    max-width: 1.7em;
    height: 1.7em;
    padding: 0.25em;
    font-size: 32px;
  }
  h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 450px) {
  .auth-code-input {
    max-width: 1.5em;
    height: 1.5em;
    padding: 0.2em;
    font-size: 32px;
    margin-right: 5px;
  }
  .auth-code-footer {
    flex-direction: column-reverse;
  }
  .auth-code-footer-link-buttons {
    margin-top: 10px;
    flex-direction: column-reverse;
  }
}

.auth-client-mfa-factor-list-container {
  margin-bottom: 10px;
}

.auth-client-mfa-factor-list-button {
  background-color: #f1f3f8;
  border: none;
}

.ant-btn-loading-icon {
  display: inline-flex !important;
  align-items: center;
}

.ant-field-grey > textarea,
input.ant-input-grey,
span.ant-input-grey,
.ant-input-grey .ant-select-selector,
.ant-input-grey .ant-form-item-control-input-content > input,
span.ant-input-grey > * {
  background-color: #f1f3f8 !important;
}

.ant-field-grey > textarea:not(.ant-input-status-error),
input.ant-input-grey:not(.ant-input-status-error),
.ant-input-grey .ant-select-selector:not(.ant-input-status-error),
span.ant-input-grey:not(.ant-input-status-error),
.ant-input-grey .ant-form-item-control-input-content > input:not(.ant-input-status-error),
span.ant-input-grey:not(.ant-input-status-error) > * {
  border-color: #f1f3f8 !important;
}

.darkenedRadioButton .ant-radio-inner {
  border-bottom-color: black;
  border-left-color: black;
  border-top-color: black;
  border-right-color: black;
}

.darkenedCheckbox .ant-checkbox-inner {
  border-bottom-color: black;
  border-left-color: black;
  border-top-color: black;
  border-right-color: black;
}

.file-input-dropzone {
  background-color: #f1f3f8 !important;
  border-color: #2280c1 !important;
}

.ant-statistic {
  display: inline;
}

.countdown .ant-statistic-content {
  display: inline;
  font-size: unset;
}

.add-dependent-centered-container {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.anticon-block > .anticon > svg {
  display: block !important;
}

.ant-checkbox > .ant-checkbox-inner {
  border-radius: 10px;
}

.ant-select-item-option-content {
  overflow: visible !important;
  white-space: normal !important;
  line-height: 1.2;
  vertical-align: middle;
  display: inline-block;
  padding: 4px 0;
}

.network-status-alert{
  position: sticky !important;
  top: 0; 
  z-index: 1000;
  font-size: '1.2em';
}

.network-status-alert.online{
  display: none;
}
.network-status-alert.offline{
  display: flex;
}